
<template>
  <div class="relative w-full">
    <div
      class="flex w-full items-center h-6 md:h-10 relative"
      :class="[
        isSponsorForm ? 'bg-white' : 'bg-transparent border-b border-slate-300',
      ]"
      @click="handleSearchMenuFocus"
    >
      <SearchIcon
        class="basis-4 w-0 h-6 text-slate-600"
        :class="isSponsorForm ? 'md:ml-4' : ''"
      />

      <div class="flex items-center grow">
        <textarea
          id="search"
          ref="search"
          data-test-id="textarea"
          :placeholder="placeholderText ? placeholderText : isSponsorForm ? 'Search for sponsors' : 'Search for artists or collectors'"
          class="h-5 md:h-6 w-full truncate px-1.5 text-sm md:text-base outline-none resize-none bg-transparent border-0"
          aria-multiline="false"
          :value="searchText"
          v-bind="$attrs"
          aria-describedby="search-error"
          @input="handleSearchInput"
          @blur="()=>{}"
        />
      </div>

      <button
        v-if="searchText.length > 0"
        class="shrink pr-1 md:pr-4 pl-2 h-6 flex items-center text-xs"
        :class="'group cursor-pointer hover:border-none'"
        @click="handleClearSearchText"
      >
        Clear
      </button>
    </div>

    <!-- Search Menu -->
    <transition name="toggle-search">
      <div
        v-show="searchMenuOpen && searchText.length > 0"
        v-off="handleSearchMenuVOff"
        class="absolute left-0 top-14 w-full drop-shadow-lg bg-white rounded-xl z-10 min-h-[120px] md:min-h-[160px]"
      >
        <SearchAccounts
          :search-text="searchText"
          :is-sponsor-form="isSponsorForm"
          @select="($event) => isSponsorForm ? addSponsorAccount($event) : navigateToProfile($event)"
        />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref, watch , Ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'

import { useLazyQuery } from '#composables/use-apollo'

import { SEARCH_ACCOUNTS_BY_USERNAME } from '#queries/SearchAccountsByUsername'
import type { SearchAccountsByUsername, SearchAccountsByUsernameVariables, SearchAccountsByUsername_searchAccountsByUsername_accounts } from '#graphql/types/SearchAccountsByUsername'

import type { User } from './types'

import SearchAccountCard from '#components/search/search-account-card.vue'
import Body from '#components/typography/body'
import SearchSkeletonLoader from '#components/search/search-skeleton-loader.vue'
import SearchIcon from '#components/icons/search-icon.vue'
import LoadingIcon from '#components/icons/loading-icon.vue'
import SearchAccounts from '#components/search/search-accounts.vue'

const props = defineProps({
  setFocus: {
    type: Boolean,
    default: false,
  },

  isSponsorForm: {
    type: Boolean,
    default: false,
  },

  placeholderText: {
    type: String,
    required: false,
  },
})

const emit = defineEmits(['hideSearch', 'accountSelected'])

const router = useRouter()

const searchResults = ref<SearchAccountsByUsername_searchAccountsByUsername_accounts[]>([])
const searchText = ref('')
const searchMenuOpen = ref(false)
const searchLoading = ref(false)
const multipleLetterSearch = ref(false)
const searchMenuOpenInitialized = ref(false)
const search: Ref<HTMLTextAreaElement | null> = ref(null)

const handleSearchInput = (evt: Event) => {
  let value = (evt.target as HTMLInputElement).value
  value = value.replace(/[\n]/g, '')

  searchText.value = value
  searchMenuOpen.value = true

  setCursorPosition(search.value, value.length)
}

const setCursorPosition = (textarea: HTMLTextAreaElement | null, position: number) => {
  if (textarea) {
    textarea.selectionStart = position
    textarea.selectionEnd = position
    textarea.focus()
  }
}

const handleClearSearchText = () => {
  searchText.value = ''
  searchResults.value = []
}

const handleSearchMenuFocus = () => {
  searchMenuOpen.value = true
  searchMenuOpenInitialized.value = false
  search.value?.focus()
}

const handleSearchMenuVOff = () => {
  if (searchMenuOpen.value && searchMenuOpenInitialized.value ) {
    searchMenuOpen.value = false
  }

  searchMenuOpenInitialized.value = true
}

const navigateToProfile = (profileSlug: string) => {
  router.push({ name: 'profile', params: { profileSlug } })

  searchMenuOpen.value = false
  searchMenuOpenInitialized.value = false
  searchText.value = ''
  search.value?.blur()

  emit('hideSearch')
}

const addSponsorAccount = (user: User) => {
  searchMenuOpen.value = false
  searchMenuOpenInitialized.value = false
  searchText.value = ''
  search.value?.blur()

  emit('accountSelected', user)
}

const {
  load: searchAccountsByUsername,
} = useLazyQuery<SearchAccountsByUsername, SearchAccountsByUsernameVariables>(SEARCH_ACCOUNTS_BY_USERNAME)

const handleSearchText = async () => {
  const input = {  input:{ searchText:searchText.value as string }  }

  try {
    searchLoading.value = true

    if (searchText.value.length > 1) {
      multipleLetterSearch.value = true
    }

    const result = await searchAccountsByUsername(input, { fetchPolicy: 'network-only' })
    if (multipleLetterSearch.value && searchText.value.length <= 1) {
      multipleLetterSearch.value = false
    }

    searchLoading.value = false

    if (result.data?.searchAccountsByUsername) {
      searchResults.value= result.data.searchAccountsByUsername.accounts as SearchAccountsByUsername_searchAccountsByUsername_accounts[]
    }
  } catch (error) {
    searchLoading.value = false
    console.log('error', error)
  }
}

watch(
  searchText,
  (newSearchText) => {
    if (newSearchText.length > 0) {
      handleSearchText()
    } else {
      searchResults.value = []
    }
  }
)

onMounted(() => {
  if (props.setFocus) {
    handleSearchMenuFocus()
  }
})

</script>

<style>
  .toggle-search-enter-from {
    transform: translateY(10px);
    opacity: 0;
  }

  .toggle-search-enter-to {
    transform: translateY(0px);
    opacity: 0.5;
  }

  .toggle-search-leave-to {
    transform: translateY(10px);
    opacity: 0;
  }

  .toggle-search-leave-active,
  .toggle-search-enter-active {
    transition: all 200ms ease-in-out;
  }
</style>
